import { isDK } from '@helpers/countryHelpers';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  CART_ITEM_SOURCE_TYPES,
  CartItemSourceContext,
} from 'vivino-js/context/CartItemSourceContext';
import { CartsProvider } from 'vivino-js/context/CartsContext';
import Page from 'vivino-js/utilities/Page';

import AppBanner from '@components/AppBanner/AppBanner';
import BrowserBanner from '@components/BrowserBanner/Browserbanner';
import Navigation from '@components/Navigation/Navigation';

function render() {
  const navigation = document.getElementById('navigation-container');
  const countryCode = window.__PRELOADED_COUNTRY_CODE__;
  const trackingScreenName = window.__PRELOADED_STATE__.trackingScreenName;
  const pdpInformation =
    window.__PRELOADED_STATE__?.winePageInformation ||
    window.__PRELOADED_STATE__?.vintagePageInformation ||
    window.__PRELOADED_STATE__?.offerPageInformation;

  const showBanner =
    pdpInformation?.price && String(pdpInformation?.price?.type).toLowerCase() !== 'ppc';

  const userNotification = window.__PRELOADED_USER_NOTIFICATION__;
  if (userNotification) {
    const urlWithoutParameter = location.href.replace(
      `user_notification_type=${userNotification}`,
      ''
    );
    history.replaceState(null, null, urlWithoutParameter);
  }

  navigation &&
    ReactDOM.render(
      <Page latest>
        <CartsProvider>
          <CartItemSourceContext.Provider value={CART_ITEM_SOURCE_TYPES.EXPLORE_NAV}>
            {showBanner && <AppBanner />}
            {isDK(countryCode) && <BrowserBanner />}
            <Navigation
              menuData={window.menuData}
              countryCode={countryCode}
              stateCode={window.__PRELOADED_STATE_CODE__}
              initialUserNotification={window.__PRELOADED_USER_NOTIFICATION__}
              isGhosting={window.__PRELOADED_STATE__?.isGhosting || null}
              cellarLinks={window.__PRELOADED_CELLAR_LINKS__ || []}
              trackingScreenName={trackingScreenName}
            />
          </CartItemSourceContext.Provider>
        </CartsProvider>
      </Page>,
      navigation
    );
}

render();

export { render };
